import { useEffect } from "react";
//import { ThumbUpIcon, ThumbDownIcon, EyeIcon } from "@heroicons/react/solid";
import { Link, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deletePostAction, fetchPostDetailsAction } from "../../../redux/slices/posts/postSlices";
import DateFormatter from "../../utils/DateFormatter";
import { fetchGroupsAction } from "../../../redux/slices/group/groupSlices";
import LoadingComponent from "../../utils/LoadingComponent";
import AddComment from "../PostComments/AddComment";
import CommentsList from "../PostComments/CommentsList";

export default function DetailPost(props) {
  const id = props.match.params.slug
  const dispatch = useDispatch();

  //select post details from store
  const post = useSelector(state => state?.post);
  const { postDetails, loading, appErr, serverErr, isDeleted } = post;

  //comment
  const comment = useSelector(state => state?.comment);
  const { commentCreated, commentDeleted } = comment;

  useEffect(() => {
    dispatch(fetchPostDetailsAction(id));
  }, [id, dispatch, commentCreated, commentDeleted]);

  //Get login user
  const user = useSelector(state => state.users);
  const { userAuth } = user;

  const isCreatedBy = postDetails?.user?._id === userAuth?.userId; //userAuth?._id;
  console.log('isCreatedBy', isCreatedBy, postDetails, userAuth);

  //redirect
  if (isDeleted) return <Redirect to="/posts" />;

  return (
    <>






{loading ? (
        <div className="h-screen">
          <LoadingComponent />
        </div>
      ) : appErr || serverErr ? (
        <h1 className="h-screen text-red-400 text-xl">
          {serverErr} {appErr}
        </h1>
      ) : (
        <section className="py-20 2xl:py-40 bg-gray-800 overflow-hidden">
          <div className="container px-4 mx-auto">
            {/* Post Image */}
            <img
              className="mb-24 w-full h-96 object-cover"
              src={postDetails?.image}
              alt=""
            />
            <div className="max-w-2xl mx-auto text-center">
              <h2 className="mt-7 mb-14 text-6xl 2xl:text-7xl text-white font-bold font-heading">
                {postDetails?.title}
              </h2>

              {/* User */}
              <div className="inline-flex pt-14 mb-14 items-center border-t border-gray-500">
                <img
                  className="mr-8 w-20 lg:w-24 h-20 lg:h-24 rounded-full"
                  src={postDetails?.user?.profilePhoto}
                  alt=""
                />
                <div className="text-left">
                  <h4 className="mb-1 text-2xl font-bold text-gray-50">
                    <span className="text-xl lg:text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-br from-yellow-200 to-orange-600">
                      {postDetails?.user?.firstName}{" "}
                      {postDetails?.user?.lastName}{" "}
                    </span>
                  </h4>
                  <p className="text-gray-500">----TODO: returning wrong date
                    {<DateFormatter date={postDetails?.createdAt} />}--
                  </p>
                </div>
              </div>
              {/* Post description */}
              <div class="max-w-xl mx-auto">
                <div class="mb-6 text-left  text-xl text-gray-200">
                  {postDetails?.description}

                  {/* Show delete and update  if it was created by the user */}
                  {isCreatedBy ? (
                    <p class="flex">
                      <Link to={`/updatepost/${postDetails?._id}`} class="p-3">
                        PencilAltIcon
                      </Link>
                      <button
                        onClick={() =>
                          dispatch(deletePostAction(postDetails?._id))
                        }
                        class="ml-3"
                      >
                        TrashIcon
                      </button>
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          {/* Add comment Form component here */}
          {userAuth ? <AddComment postId={id} /> : null}
          <div className="flex justify-center  items-center">
            {/* <CommentsList comments={post?.comments} postId={post?._id} /> */}
            <CommentsList comments={postDetails?.comments} />
          </div>
        </section>
      )}







    </>
  );
}
