import { useState } from 'react';
import { useFormik } from "formik";
import * as Yup from "yup";
import Dropzone from "react-dropzone";
import { Redirect } from "react-router-dom";
// TODO: GET SCSS Modules instead, seems popular
//import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { createpostAction } from "../../../redux/slices/posts/postSlices";
import GroupDropDown from "../GroupFormPage/section/GroupDropDown";
import axios from 'axios';
import { Icon } from 'antd';

//Form schema
const formSchema = Yup.object({
  title: Yup.string().required("Title is required"),
  description: Yup.string().required("Description is required"),
  group: Yup.object().required("Group is required"),
  //image: Yup.string().required("Image is required"),
});

// //css for dropzone
// const Container = styled.div`
//   flex: 1;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   padding: 20px;
//   border-width: 2px;
//   border-radius: 2px;
//   border-style: dashed;
//   background-color: #fafafa;
//   color: #bdbdbd;
//   border-color:'red'
//   transition: border 0.24s ease-in-out;
// `;

export default function CreatePost() {
  const dispatch = useDispatch();
  //select store data
  const post = useSelector(state => state?.post);
  const { isCreated, loading, appErr, serverErr } = post;
  const [FilePath, setFilePath] = useState("");
  const [Images, setImages] = useState([]);
  const [Image, setImage] = useState("");
  const updateImages = (newImages) => {
    setImages(newImages);
  };
  var domain = window.location.host === 'bjjdrills.com' || window.location.host === 'www.bjjdrills.com' ? window.location.origin + '/' : 'http://localhost:5000/';

  const onDrop = (files) => {

    let formData = new FormData();

    const config = {
        header: { 'content-type': 'multipart/form-data' }
    }

    formData.append("file", files[0]);
//TODO: change this to post/uploadImage, but getting an error right now.
    axios.post('/api/post/uploadImage', formData, config)
      .then(response => {
          if (response.data.success) {

              setImages([...Images, response.data.image])
              setImage(response.data.image)
              updateImages([...Images, response.data.image])

          } else {
              alert('Failed to save the Image in Server')
          }
      });
}

  //select store data
  //const post = useSelector(state => state?.post);
  //const { isCreated, loading, appErr, serverErr } = post;
  //formik
  // TODO: update category below
  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      group: "",
      image: "",
    },
    onSubmit: values => {
      //dispath the action
      console.log('values',values, Images);
      const data = {
        description: values?.description,
        title: values?.title,
        group: values?.group?.id,
        image: Image //values?.image,
      };
      console.log('values',data);
      dispatch(createpostAction(data));
    },
    validationSchema: formSchema,
  });

  //redirect
  if (isCreated) return <Redirect to="/posts" />;

  return (
    <>
      <div>
        <div>
          <h2>
            Create Post
          </h2>
          <p>
            Share your ideas to the world.
          </p>

          {appErr || serverErr ? (
            <p>
              {serverErr} {appErr}
            </p>
          ) : null}
        </div>
        <div>
          <div>
            <form onSubmit={formik.handleSubmit}>
              <div>
                <label htmlFor="title">
                  Title
                </label>
                <div>
                  {/* Title */}
                  <input value={formik.values.title} onChange={formik.handleChange("title")} onBlur={formik.handleBlur("title")} id="title" name="title" type="title" autoComplete="title" />
                </div>
                {/* Err msg */}
                <div>
                {formik?.touched?.title && formik?.errors?.title}
                  {formik?.touched?.title}{formik?.errors?.title}
                </div>
              </div>
              <div>
                <label htmlFor="group">
                  Select Group
                </label>
                <GroupDropDown
                  value={formik.values.group?.label}
                  onChange={formik.setFieldValue}
                  onBlur={formik.setFieldTouched}
                  error={formik.errors.group}
                  touched={formik.touched.group}
                />
              </div>
              <div>
                <label htmlFor="description">
                  Description
                </label>
                <div>
                {/* Description */}
                <textarea rows="5" cols="10" type="text" value={formik.values.description} onChange={formik.handleChange("description")} onBlur={formik.handleBlur("description")} style={{ width: '100%'}}></textarea>
                </div>
                {/* Err msg */}
                {formik?.touched?.description && formik.errors?.description}
                <div>{formik?.touched?.description}{formik?.errors?.description}</div>
              </div>
              <div>
                <label htmlFor="upload">Select image to upload</label>
                <Dropzone
                  onDrop={onDrop}
                  multiple={false}
                  maxSize={800000000}>
                  {({ getRootProps, getInputProps }) => (
                      <div style={{ width: '300px', height: '240px', border: '1px solid lightgray', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                          {...getRootProps()}
                      >
                          <input {...getInputProps()} />
                          <Icon type="plus" style={{ fontSize: '3rem' }} />

                      </div>
                  )}
                </Dropzone>

                <div style={{ display: 'flex', width: '350px', height: '240px', overflowX: 'scroll' }}>

                  {Images.map((image, index) => (
                    <div key="{index}"> {/*onClick={() => onDelete(image)} */}
                      <img style={{ minWidth: '300px', width: '300px', height: '240px' }} src={`${domain}${image}`} alt={`productImg-${index}`} />
                    </div>
                  ))}


                </div>


                {/*
                <Dropzone onBlur={formik.handleBlur("image")} accept="image/jpeg, image/png"
                  onDrop={acceptedFiles => {
                    formik.setFieldValue("image", acceptedFiles[0]);
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div className="container">
                      <div
                        {...getRootProps({
                          className: "dropzone",
                          onDrop: event => event.stopPropagation(),
                        })}
                      >
                        <input {...getInputProps()} />
                        <p>Click here to select image</p>
                      </div>
                    </div>
                  )}
                </Dropzone>
                */}
              </div>
              <div>
                {loading ? (
                  <button disabled>
                    Loading please wait...
                  </button>
                ) : (
                  <button type="submit">
                    Create
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
