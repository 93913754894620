import React, { useState } from 'react';
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { createGroupAction } from "../../../../redux/slices/group/groupSlices";

const formSchema = Yup.object({
	name: Yup.string().required("Name is required"),
});

const AddGroupForm = props => {
	const dispatch = useDispatch();
	//formik
	const formik = useFormik({
		initialValues: {
			name: "",
			label: "",
		},
		onSubmit: values => {
			//dispath the action
			dispatch(createGroupAction(values));
		},
		validationSchema: formSchema,
	});

	//get data from store
	const state = useSelector(state => state?.group);

	const { loading, appErr, serverErr, group } = state;

	return (
		<form onSubmit={formik.handleSubmit}>
			<label>Name</label>
			<input type="text" name="name" value={formik.values.name} onChange={formik.handleChange("name")} onBlur={formik.handleBlur("name")} />
			{formik.touched.name && formik.errors.name}
			<label>Label</label>
			<input type="text" name="label" value={formik.values.label} onChange={formik.handleChange("label")} onBlur={formik.handleBlur("label")} />
			{formik.touched.label && formik.errors.label}
			{appErr || serverErr ? (
                <h2 className="text-red-500 text-center text-lg">
                  {serverErr} {appErr}
                </h2>
              ) : null}
			<button>{ loading ? <div>Please wait</div> : <div>Add new Group</div> }</button>
		</form>
	)
}

export default AddGroupForm
