import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { DOMAIN_URL } from '../../Config';

function SearchResultsPage(props) {
	const [Videos, setVideos] = useState([]);
	const [Limit, setLimit] = useState(8);
    const [Skip, setSkip] = useState(0);
    const limit = 1;

	const searchTerm = props.match.params.searchTerm.toLowerCase();

	const variables = {
        searchTerm: searchTerm,
        skip: Skip,
        limit: Limit
    }

    useEffect(() => {

        fetchVideos();
        
    }, [props.match.params.searchTerm]);

    const fetchVideos = () => {

        axios.post('/api/video/', variables)
        .then(response => {
            if (response.data.success) {
                setVideos([...Videos, ...response.data.videos]);
                //setVideos(response.data.videos);
                setSkip(Skip + Limit);

                // Save history result if a video is found
                if (response.data.videos.length){
                    axios.post('/api/searchhistory/saveHistory', {term: searchTerm})
                    .then(response => {
                        if (response.data.success) {
                            // setComment("")
                            // props.refreshFunction(response.data.result)
                        } else {
                            alert('Failed to save Comment')
                        }
                    });
                }
            } else {
                alert('Failed to get Videos');
            }
        });

    }

    const handleClick = () => {
        fetchVideos();
    }

    const renderCards = Videos.map((video, index) => {
        return  <div class="col-12 col-sm-12 col col-md-6 col col-lg-4 col col-xl-3 py-1">
                    <div style={{ position: 'relative' }}>
                        <a href={`/video/${video.slug}`} >
                            {/* https://stackoverflow.com/questions/48703510/change-image-on-hover-in-jsx */}
                            <img style={{ width: '100%' }} className="img-fluid" width="320" height="180" alt={video.title} src={`${DOMAIN_URL}${video.thumbnail}`} onMouseOver={e => (e.currentTarget.src = DOMAIN_URL + video.gifPreviewFilePath)} onMouseOut={e => (e.currentTarget.src = DOMAIN_URL + video.thumbnail)} />
                        </a>
                    </div>
                    <div className="my-1 font-weight-bold2" style={{fontSize: '14px'}}><a href={`/video/${video.slug}`} >{video.title}</a></div>
                </div>
    });
    /*
    const renderCards2 = Videos.map((video, index) => {
        var minutes = Math.floor(video.duration / 60),
            seconds = Math.floor(video.duration - minutes * 60);

        return  <div class="col-12 col-sm-12 col col-md-6 col col-lg-4 col col-xl-3 py-1">
                    <div style={{ position: 'relative' }}>
                        <a href={`/video/${video.slug}`} >
                            <img style={{ width: '100%' }} alt={video.title} src={`http://localhost:5000/${video.thumbnail}`} />
                            <div className="duration"
                                style={{ bottom: 0, right:0, position: 'absolute', margin: '4px', 
                                color: '#fff', backgroundColor: 'rgba(17, 17, 17, 0.8)', opacity: 0.8, 
                                padding: '2px 4px', borderRadius:'2px', letterSpacing:'0.5px', fontSize:'12px',
                                fontWeight:'500', lineHeight:'12px' }}>
                                <span>{minutes} : {seconds}</span>
                            </div>
                        </a>                        
                    </div>
                    <span>{video.title} (v)</span>
                </div>
    });
    */

    return (
        <div class="row">
            <h4 className="widget-title">Search Results: {searchTerm}</h4>
            {renderCards}
            <br />
            <div style={{ display: 'none', justifyContent: 'center' }}>
                <button onClick={handleClick}> Load More </button>
            </div>
        </div>
    )
}

export default SearchResultsPage
