import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import { fetchGroupsAction } from "../../../../redux/slices/group/groupSlices";

const GroupDropDown = props => {
  console.log(props);
  //dispatch action
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchGroupsAction());
  }, [dispatch]);
  //select groups
  const group = useSelector(state => state?.group);
  const { groupList, loading, appErr, serverErr } = group;
  const allGroups = groupList?.map(group => {
    return {
      label: group?.label,
      name: group?.name,
      id: group?._id,
      value: group?._id,
    };
  });

  //handleChange
  const handleChange = value => {
    props.onChange("group", value);
  };
  //handleBlur
  const handleBlur = () => {
    props.onBlur("group", true);
  };
  return (
    <div style={{ margin: "1rem 0" }}>
      {loading ? (
        <h3 className="text-base text-green-600">
          Groups list are loading please wait...
        </h3>
      ) : (
        <Select
          onChange={handleChange}
          onBlur={handleBlur}
          id="group"
          options={allGroups}
          value={props?.value?.id}
        />
      )}
      {/* Display */}
      {/* TODO: check to see how to make this work, do we need id passed here or props?.value?.name (like before) */}
      {props?.error && (
        <div style={{ color: "red", marginTop: ".5rem" }}>{props?.error}</div>
      )}
    </div>
  );
};

export default GroupDropDown;
