import React, { useState, useEffect } from 'react';
import { Typography, Button, Form, message, Input, Icon } from 'antd';
import Dropzone from 'react-dropzone';
import axios from 'axios';
import { useSelector } from "react-redux";
import FileUpload from './Sections/FileUpload';
import ChainedSelect from '../../utils/ChainedSelect';

const { TextArea } = Input;


function UploadVideoPage(props) {
    const user = useSelector(state => state.users);

    const [title, setTitle] = useState("");
    const [slug, setSlug] = useState("");
    const [Description, setDescription] = useState("");
    const [gifDuration, setGifDuration] = useState("");
    const [gifStartTime, setGifStartTime] = useState("");
    const [contributor, setContributor] = useState(0);
    const [Categories, setCategories] = useState("Film & Animation");
    const [FilePath, setFilePath] = useState("");
    const [Duration, setDuration] = useState("");
    const [Thumbnail, setThumbnail] = useState("");
    //const [webmFilePath, setWebmFilePath] = useState("");
    const [mp4FilePath, setMp4FilePath] = useState("");
    const [gifFilePath, setGifFilePath] = useState("");
    const [gifPreviewFilePath, setGifPreviewFilePath] = useState("");
    const [Images, setImages] = useState([]);
    const [TechniqueList, setTechniqueList] = useState([]);
    // The most child selected technique ID number
    const [TechniqueValue, setTechniqueValue] = useState("");
    
    const [youTube, setYouTube] = useState("");
    const [instagram, setInstagram] = useState("");
    const [contributors, setContributors] = useState([]);

    const techniqueVariable = {
        techniqueId: null
    };

    useEffect(() => {
        axios.post('/api/category/get-categories-by-parent', techniqueVariable)
            .then(response => {
                if (response.data.success) {
                    setTechniqueList([response.data.techniques]);
                } else {
                    alert('Failed to get video Info')
                }
            });

        axios.post('/api/contributor/getContributors', {})
            .then(response => {
                if (response.data.success) {
                    setContributors(response.data.contributors);
                } else {
                    alert('Failed to get video Info');
                }
            });
    }, []);

    // newTechnique is the child
    const updateTechnique = (newTechnique, index, value) => {
        console.log('-TechniqueList:', TechniqueList);
        console.log('-newTechnique:', newTechnique);
        console.log('-value', value);
        console.log('-index', index);

        // At the most child value
        setTechniqueValue(value);

        // TODO: check to see if this is working properly - TechniqueList.splice(index + 1, TechniqueList.length - index + 1);
        // Do not run if no children exist on the child
        if (newTechnique.length != 0){
            if (TechniqueList.length - 1 >= index){
                TechniqueList[index] = newTechnique;

                // Remove anything that exists after the index
                // When something is selected before again
                TechniqueList.splice(index + 1, TechniqueList.length - index + 1);

                // [...] updates the state
                setTechniqueList([...TechniqueList]);

            } else {
                setTechniqueList(TechniqueList.concat([newTechnique]));
            }
        } else {
            TechniqueList.splice(index + 1, TechniqueList.length - index + 1);
        }
    }

    const handleChangeTitle = (event) => {
        setTitle(event.currentTarget.value);
    }

    const handleChangeSlug = (event) => {
        setSlug(event.currentTarget.value);
    }

    const handleChangeGifDuration = (event) => {
        setGifDuration(event.currentTarget.value);
    }

    const handleChangeGifStartTime = (event) => {
        setGifStartTime(event.currentTarget.value);
    }

    const handleChangeDecsription = (event) => {
        setDescription(event.currentTarget.value);
    }

    const handleChangeContributor = (event) => {
        setContributor(event.currentTarget.value);
    }

    const handleChangeTwo = (event) => {
        setCategories(event.currentTarget.value);
    }

    const updateImages = (newImages) => {
        setImages(newImages);
    }

    //const handleYouTube = async (event) => {
    const handleYouTube = (event) => {

        if (event.currentTarget.value){

            setYouTube(event.currentTarget.value);

            let fileName = Date.now() + '.mp4';

            let variable = {
                url: event.currentTarget.value,
                filePath: 'uploads/' + fileName,
                duration: gifDuration, 
                startTime: gifStartTime
            };

            //await 
            axios.post('/api/video/youtube', variable)
            .then(response => {
                console.log('done!!!!!!!!');
                if (response.data.success) {
    console.log('response.data', 'DONE!!!!!!')

    //                let variable = {
    //                     filePath: response.data.data.path,
    //                     fileName: fileName,
    //                     duration: gifDuration, 
    //                     startTime: gifStartTime
    //                 }
    // console.log('variable:', variable)
                     setFilePath(variable.filePath);

    //                 //gerenate thumbnail with this filepath ! 




new Promise(function(resolve, reject) {

resolve(1)

}).then(function(result) { // (**)

                    return axios.post('/api/video/thumbnail', variable)
                    .then(response => {
                        if (response.data.success) {
                            setDuration(response.data.fileDuration);
                            setThumbnail(response.data.thumbsFilePath);
                        } else {
                            alert('Failed to make the thumbnails');
                        }
                    });
 
/*
}).then(function(result) { // (***)

                    return axios.post('/api/video/webm', variable)
                    .then(response => {
                        if (response.data.success) {
                            setWebmFilePath(response.data.webmFilePath);
                        } else {
                            alert('Failed to make the thumbnails');
                        }
                    });
*/
}).then(function(result) {

                    return axios.post('/api/video/mp4', variable)
                    .then(response => {
                        if (response.data.success) {
                            setMp4FilePath(response.data.mp4FilePath);
                        } else {
                            alert('Failed to make the thumbnails');
                        }
                    });

}).then(function(result) {
                    return axios.post('/api/video/gifPreview', variable)
                    .then(response => {
                        if (response.data.success) {
                            setGifPreviewFilePath(response.data.gifPreviewFilePath);
                        } else {
                            alert('Failed to make the thumbnails');
                        }
                    });

});
/*
.then(function(result) {
                    return axios.post('/api/video/gif', variable)
                    .then(response => {
                        if (response.data.success) {
                            setGifFilePath(response.data.gifFilePath);
                        } else {
                            alert('Failed to make the thumbnails');
                        }
                    });

});
*/


/*

                    axios.post('/api/video/thumbnail', variable)
                    .then(response => {
                        if (response.data.success) {
                            setDuration(response.data.fileDuration);
                            setThumbnail(response.data.thumbsFilePath);
                        } else {
                            alert('Failed to make the thumbnails');
                        }
                    });
 
                    axios.post('/api/video/webm', variable)
                    .then(response => {
                        if (response.data.success) {
                            setWebmFilePath(response.data.webmFilePath);
                        } else {
                            alert('Failed to make the thumbnails');
                        }
                    });

                    axios.post('/api/video/mp4', variable)
                    .then(response => {
                        if (response.data.success) {
                            setMp4FilePath(response.data.mp4FilePath);
                        } else {
                            alert('Failed to make the thumbnails');
                        }
                    });

                    axios.post('/api/video/gif', variable)
                    .then(response => {
                        if (response.data.success) {
                            setGifFilePath(response.data.gifFilePath);
                        } else {
                            alert('Failed to make the thumbnails');
                        }
                    });
                   
                    axios.post('/api/video/gifPreview', variable)
                    .then(response => {
                        if (response.data.success) {
                            setGifPreviewFilePath(response.data.gifPreviewFilePath);
                        } else {
                            alert('Failed to make the thumbnails');
                        }
                    });
*/
                } else {
                    alert('failed to save the video in server');
                }
            });
        }
    }

    const handleInstagram = (event) => {

        if (event.currentTarget.value){

            setInstagram(event.currentTarget.value);

            let fileName = Date.now() + '.mp4';

            let variable = {
                url: event.currentTarget.value,
                filePath: 'uploads/' + fileName,
                duration: gifDuration, 
                startTime: gifStartTime
            };

            //await 
            axios.post('/api/video/instagram', variable)
            .then(response => {
                console.log('done!!!!!!!!');
                if (response.data.success) {
    console.log('response.data', 'DONE!!!!!!')

    //                let variable = {
    //                     filePath: response.data.data.path,
    //                     fileName: fileName,
    //                     duration: gifDuration, 
    //                     startTime: gifStartTime
    //                 }
    // console.log('variable:', variable)
                     setFilePath(variable.filePath);

    //                 //gerenate thumbnail with this filepath ! 



new Promise(function(resolve, reject) {

resolve(1)

}).then(function(result) { // (**)
                    return axios.post('/api/video/gifPreview', variable)
                    .then(response => {
                        if (response.data.success) {
                            setGifPreviewFilePath(response.data.gifPreviewFilePath);
                        } else {
                            alert('Failed to make the thumbnails');
                        }
                    });

 
/*
}).then(function(result) { // (***)

                    return axios.post('/api/video/webm', variable)
                    .then(response => {
                        if (response.data.success) {
                            setWebmFilePath(response.data.webmFilePath);
                        } else {
                            alert('Failed to make the thumbnails');
                        }
                    });
*/
}).then(function(result) {

                    return axios.post('/api/video/mp4', variable)
                    .then(response => {
                        if (response.data.success) {
                            setMp4FilePath(response.data.mp4FilePath);
                        } else {
                            alert('Failed to make the thumbnails');
                        }
                    });

}).then(function(result) {

                    return axios.post('/api/video/thumbnail', variable)
                    .then(response => {
                        if (response.data.success) {
                            setDuration(response.data.fileDuration);
                            setThumbnail(response.data.thumbsFilePath);
                        } else {
                            alert('Failed to make the thumbnails');
                        }
                    });

});

/*
.then(function(result) {

                    return axios.post('/api/video/gif', variable)
                    .then(response => {
                        if (response.data.success) {
                            setGifFilePath(response.data.gifFilePath);
                        } else {
                            alert('Failed to make the thumbnails');
                        }
                    });

});
*/



/*
                    axios.post('/api/video/thumbnail', variable)
                    .then(response => {
                        if (response.data.success) {
                            setDuration(response.data.fileDuration);
                            setThumbnail(response.data.thumbsFilePath);
                        } else {
                            alert('Failed to make the thumbnails');
                        }
                    });

                    axios.post('/api/video/webm', variable)
                    .then(response => {
                        if (response.data.success) {
                            setWebmFilePath(response.data.webmFilePath);
                        } else {
                            alert('Failed to make the thumbnails');
                        }
                    });

                    axios.post('/api/video/mp4', variable)
                    .then(response => {
                        if (response.data.success) {
                            setMp4FilePath(response.data.mp4FilePath);
                        } else {
                            alert('Failed to make the thumbnails');
                        }
                    });

                    axios.post('/api/video/gif', variable)
                    .then(response => {
                        if (response.data.success) {
                            setGifFilePath(response.data.gifFilePath);
                        } else {
                            alert('Failed to make the thumbnails');
                        }
                    });
                   
                    axios.post('/api/video/gifPreview', variable)
                    .then(response => {
                        if (response.data.success) {
                            setGifPreviewFilePath(response.data.gifPreviewFilePath);
                        } else {
                            alert('Failed to make the thumbnails');
                        }
                    });
*/
                } else {
                    alert('failed to save the video in server');
                }
            });
        }
    }

    const onSubmit = (event) => {

        event.preventDefault();

        if (user.userData && !user.userData.isAuth) {
            return alert('Please Log in First')
        }

        if (title === "" || slug === "" || Description === "" ||
            Categories === "" || FilePath === "" ||
            Duration === "") { // || Thumbnail === ""
            return alert('Please first fill all the fields')
        }

        const variables = {
            writer: user.userData._id,
            title: title,
            slug: slug,
            description: Description,
            contributor: contributor,
            filePath: FilePath,
            category: Categories,
            duration: Duration,
            thumbnail: Thumbnail,
            images: Images,
            category: TechniqueValue,
            //webmFilePath: webmFilePath,
            mp4FilePath: mp4FilePath,
            gifFilePath: gifFilePath,
            gifPreviewFilePath: gifPreviewFilePath,
            youtubeLink: youTube,
            instagramLink: instagram
        }

        axios.post('/api/video/uploadVideo', variables)
            .then(response => {
                if (response.data.success) {
                    alert('video Uploaded Successfully')
                    props.history.push('/')
                } else {
                    alert('Failed to upload video')
                }
            })

    }

    const onDrop = (files) => {

        let formData = new FormData();

        const config = {
            header: { 'content-type': 'multipart/form-data' }
        }

        formData.append("file", files[0]);

        axios.post('/api/video/uploadfiles', formData, config)
        .then(response => {
            if (response.data.success) {

                let variable = {
                    filePath: response.data.filePath,
                    fileName: response.data.fileName,
                    duration: gifDuration, 
                    startTime: gifStartTime
                }

                setFilePath(response.data.filePath);

                //gerenate thumbnail with this filepath ! 


new Promise(function(resolve, reject) {

resolve(1)

}).then(function(result) { // (**)

                return axios.post('/api/video/gifPreview', variable)
                .then(response => {
                    if (response.data.success) {
                        setGifPreviewFilePath(response.data.gifPreviewFilePath);
                    } else {
                        alert('Failed to make the thumbnails');
                    }
                });
 
/*
}).then(function(result) { // (***)

                    return axios.post('/api/video/webm', variable)
                    .then(response => {
                        if (response.data.success) {
                            setWebmFilePath(response.data.webmFilePath);
                        } else {
                            alert('Failed to make the thumbnails');
                        }
                    });
*/
}).then(function(result) {


                 return axios.post('/api/video/mp4', variable)
                .then(response => {
                    if (response.data.success) {
                        setMp4FilePath(response.data.mp4FilePath);
                    } else {
                        alert('Failed to make the thumbnails');
                    }
                });

}).then(function(result) {

                return axios.post('/api/video/thumbnail', variable)
                .then(response => {
                    if (response.data.success) {
                        setDuration(response.data.fileDuration);
                        setThumbnail(response.data.thumbsFilePath);
                    } else {
                        alert('Failed to make the thumbnails');
                    }
                });

});

/*.then(function(result) {

                    return axios.post('/api/video/gif', variable)
                    .then(response => {
                        if (response.data.success) {
                            setGifFilePath(response.data.gifFilePath);
                        } else {
                            alert('Failed to make the thumbnails');
                        }
                    });

});
*/
                
            } else {
                alert('failed to save the video in server');
            }
        });
    }

    var domain = window.location.host === 'bjjdrills.com' || window.location.host === 'www.bjjdrills.com' ? window.location.origin + '/' : 'http://localhost:5000/';

    return (
        <div style={{ maxWidth: '700px', margin: '2rem auto' }}>
            <div style={{ textAlign: 'center', marginBottom: '2rem' }}>
                <h1> Upload Video</h1>
            </div>

            <Form onSubmit={onSubmit}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Dropzone
                        onDrop={onDrop}
                        multiple={false}
                        maxSize={800000000}>
                        {({ getRootProps, getInputProps }) => (
                            <div style={{ width: '300px', height: '240px', border: '1px solid lightgray', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                {...getRootProps()}
                            >
                                <input {...getInputProps()} />
                                <Icon type="plus" style={{ fontSize: '3rem' }} />

                            </div>
                        )}
                    </Dropzone>

                    {Thumbnail !== "" &&
                        <div>
                            <img src={`${domain}${Thumbnail}`} alt="preview" />
                        </div>
                    }
                </div>

                <br /><br />

                <FileUpload refreshFunction={updateImages} />
                <br /><br />

                <label>YouTube Link</label>
                <Input
                    onChange={handleYouTube}
                    value={youTube}
                    disabled={!(slug && gifStartTime && gifDuration)}
                />
                <br /><br />

                <label>Instagram Link</label>
                <Input
                    onChange={handleInstagram}
                    value={instagram}
                    disabled={!(slug && gifStartTime && gifDuration)}
                />
                <br /><br />

                <label>Title</label>
                <Input
                    onChange={handleChangeTitle}
                    value={title}
                />
                <br /><br />

                <label>Slug</label>
                <Input
                    onChange={handleChangeSlug}
                    value={slug}
                />
                <br /><br />

                <label>Start Time</label>
                <Input
                    onChange={handleChangeGifStartTime}
                    value={gifStartTime}
                />
                <br /><br />

                <label>Duration</label>
                <Input
                    onChange={handleChangeGifDuration}
                    value={gifDuration}
                />
                <br /><br />

                <label>Description</label>
                <TextArea
                    onChange={handleChangeDecsription}
                    value={Description}
                />
                <br /><br />

                <select onChange={handleChangeContributor}>
                <option></option>
                    {contributors.map((item, index) => (
                        <option key={index} value={item._id}>{item.name}</option>
                    ))}
                </select>
                <br /><br />

                <div className="d-flex justify-content-start">
                {TechniqueList.map((technique, index) => (
                    <ChainedSelect TechniqueList={technique} refreshFunction={updateTechnique} index={index} key={index} />
                ))}
                </div>

                <br /><br />

                <Button type="primary" size="large" onClick={onSubmit}>
                    Submit
                </Button>

            </Form>
        </div>
    )
}

export default UploadVideoPage