import React, { useState } from 'react'

const AddContributorForm = props => {
	const initialFormState = { _id: null, name: '', facebook: '', youtube: '', instagram: '', website: '' }
	const [ contributor, setContributor ] = useState(initialFormState)

	const handleInputChange = event => {
		const { name, value } = event.target

		setContributor({ ...contributor, [name]: value })
	}

	return (
		<form
			onSubmit={event => {
				event.preventDefault();
				if (!contributor.name) return; // || !contributor.parent

				props.addContributor(contributor);
				setContributor(initialFormState);
			}}
		>
			<label>Name</label>
			<input type="text" name="name" value={contributor.name} onChange={handleInputChange} />
			<label>Facebook</label>
			<input type="text" name="facebook" value={contributor.facebook} onChange={handleInputChange} />
			<label>Youtube</label>
			<input type="text" name="youtube" value={contributor.youtube} onChange={handleInputChange} />
			<label>Instagram</label>
			<input type="text" name="instagram" value={contributor.instagram} onChange={handleInputChange} />
			<label>Website</label>
			<input type="text" name="website" value={contributor.website} onChange={handleInputChange} />
			<button>Add new Contributor</button>
		</form>
	)
}

export default AddContributorForm
