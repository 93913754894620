import React, { useState } from 'react'

const AddCategoryForm = props => {
	const initialFormState = { _id: null, name: '', parent: '' }
	const [ category, setCategory ] = useState(initialFormState)

	const handleInputChange = event => {
		const { name, value } = event.target

		setCategory({ ...category, [name]: value })
	}

	return (
		<form
			onSubmit={event => {
				event.preventDefault();
				if (!category.name) return; // || !category.parent

				props.addCategory(category);
				setCategory(initialFormState);
			}}
		>
			<label>Name</label>
			<input type="text" name="name" value={category.name} onChange={handleInputChange} />
			<label>Parent</label>
			<input type="text" name="parent" value={category.parent} onChange={handleInputChange} />
			<button>Add new Category</button>
		</form>
	)
}

export default AddCategoryForm
