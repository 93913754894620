import React, { useState } from 'react'
import { useSelector } from "react-redux";
import axios from 'axios';
import { USER_SERVER } from '../../Config';
import '../NavBar/Sections/Navbar.css';
function Footer(props) {

    const user = useSelector(state => state.users);

    const logoutHandler = () => {
        axios.get(`${USER_SERVER}/logout`).then(response => {
            if (response.status === 200) {
                props.history.push("/login");
            } else {
                alert('Log Out Failed')
            }
        });
    };

    return (
        <footer className="footer">
            <div className="text-center my-2">
                <a href="https://www.fb.me/bjjdrillshq" className="mx-2"><i className="fab fa-facebook"></i></a>
                <a href="https://www.twitter.com/bjjdrillshq/" className="mx-2"><i className="fab fa-twitter"></i></a>
                <a href="https://www.instagram.com/bjjdrillshq/" className="mx-2"><i className="fab fa-instagram"></i></a>
                <a href="https://www.tumblr.com/blog/bjjdrillshq" className="mx-2"><i className="fab fa-tumblr"></i></a>
                <a href="/#" className="mx-2"><i className="fab fa-youtube"></i></a>
                <a href="/#" className="mx-2"><i className="fa fa-rss"></i></a>
            </div>
        </footer>

    )
}

export default Footer
