import React, { useEffect, useState, Fragment } from 'react';
import axios from 'axios';
import AddGroupForm from './forms/AddGroupForm';
import EditGroupForm from './forms/EditGroupForm';
import GroupTable from './tables/GroupTable';
import { useDispatch, useSelector } from "react-redux";
import { fetchGroupsAction, deleteGroupsAction } from "../../../redux/slices/group/groupSlices";

function GroupsFormPage(props) {
    const initialFormState = { _id: null, name: '', parent: '' };
    // https://medium.com/swlh/crud-operations-on-mongodb-tree-data-structure-f5afaeca1550
    // https://taniarascia.github.io/react-hooks/
    // https://www.taniarascia.com/crud-app-in-react-with-hooks/
    // Setting state
    //const [ users, setUsers ] = useState(usersData);
    const [ currentGroup, setCurrentGroup ] = useState(initialFormState);
    const [ editing, setEditing ] = useState(false);
    const [ groups, setGroups ] = useState([]);
    




  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchGroupsAction());
  }, [dispatch]);

  const group = useSelector(state => state?.group);
  const { groupList, loading, appErr, serverErr } = group;


    // useEffect(() => {
    //     axios.post('/api/group/getGroups', {})
    //         .then(response => {
    //             if (response.data.success) {
    //                 setGroups(response.data.groups);
    //             } else {
    //                 alert('Failed to get video Info');
    //             }
    //         })
    // }, []);

    // // CRUD operations
    // const addGroup = group => {
        

    //     axios.post('/api/group/addGroup', group)
    //         .then(response => {
    //             if (response.data.success) {
    //                 group._id = response.data.group._id;
    //                 setGroups([ ...groups, response.data.group ]);
    //                 //setVideos(response.data.videos)
    //             } else {
    //                 alert('Failed to get Videos');
    //             }
    //         });
    // }

    // const deleteGroup = _id => {
    //     setEditing(false);

    //     setGroups(groups.filter(group => group._id !== _id));

    //     var variables = {'group_id': _id};

    //     axios.post('/api/group/removeGroup', variables)
    //         .then(response => {
    //             if (response.data.success) {
    //                 console.log('success');
    //                 //setVideos(response.data.videos);
    //             } else {
    //                 alert('Failed to get Videos');
    //             }
    //         });
    // }

    // const updateGroup = (_id, updatedGroup) => {
    //     setEditing(false);
    //     // TODO: check to see if the parent id is different then the old one
    //     setGroups(groups.map(group => (group._id === _id ? updatedGroup : group)));

    //     var variables = {'group_id': _id,
    //                     'name': updatedGroup.name};

    //     axios.post('/api/group/renameGroup', variables)
    //         .then(response => {
    //             if (response.data.success) {
    //                 console.log('success');
    //                 //setVideos(response.data.videos);
    //             } else {
    //                 alert('Failed to get Videos');
    //             }
    //         });
    // }

    const editRow = group => {
        setEditing(true);
        setCurrentGroup({ _id: group._id, name: group.name});
    }

    return (
        <div className="row2">
            <div className="flex-row">
                <div className="flex-large">
                    {editing ? (
                        <Fragment>
                            <h2>Edit Group</h2>
                            {/*<EditGroupForm
                                editing={editing}
                                setEditing={setEditing}
                                currentGroup={currentGroup}
                                updateGroup={updateGroup}
                            />*/}
                        </Fragment>
                    ) : (
                        <Fragment>
                            <h2>Add Group</h2>
                            <AddGroupForm  /> {/* addGroup={addGroup} */}
                        </Fragment>
                    )}
                </div>
                <div className="flex-large">
                    <h2>View Group</h2>

  {loading ? (
      <div>Loading...</div>
    ) : appErr || serverErr ? (
      <h2 className="text-center text-3xl text-red-600">
        {serverErr} {serverErr}
      </h2>
    ) : groupList?.length <= 0 ? (
      <h2 className="text-center text-3xl text-green-800">
        No group Found
      </h2>
    ) : (


                    <GroupTable groups={groupList} editRow={editRow} />
        )}
                </div>
            </div>
        </div>
    )
}

export default GroupsFormPage
