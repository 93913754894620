import { React, useEffect } from "react";
//import { Redirect } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { fetchGroupsAction, deleteGroupsAction } from "../../../../redux/slices/group/groupSlices";
import DateFormatter from "../../../utils/DateFormatter";
import LoadingComponent from "../../../utils/LoadingComponent";

const GroupTable = props => {

  const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(fetchGroupsAction());
  // }, [dispatch]);

  // const group = useSelector(state => state?.group);
  // const { groupList, loading, appErr, serverErr } = group; //, isEdited, isDeleted

  //redirect
  //if (isEdited || isDeleted) return <Redirect to="/category-list" />;
return (
  <>

      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>ID</th>
            <th>Date</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
            {props.groups?.map(group => (
              <tr key={group._id}>
                <td>{group?.name}</td>
                <td>{group?._id}</td>
                <td>{<DateFormatter date={group?.createdAt} />}</td>
                <td>
                  <button
                    onClick={() => {
                      props.editRow(group)
                    }}
                    className="button muted-button"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => dispatch(deleteGroupsAction(group._id))}
                    className="button muted-button"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

  </>
  );
};

export default GroupTable
