import React, { useEffect, useState } from 'react'
import './favorite.css';
import Axios from 'axios';
import { IMAGE_URL } from '../../Config';


function FavoritePage() {

    const variables = { userFrom: localStorage.getItem('userId') }

    const [FavoritedVideos, setFavoritedVideos] = useState([])

    useEffect(() => {
        fetchFavoritedVideos();
    }, []);

    const fetchFavoritedVideos = () => {
        Axios.post('/api/favorite/getFavoritedVideo', variables)
        .then(response => {
            if (response.data.success) {
                setFavoritedVideos(response.data.favorites)
            } else {
                alert('Failed to get favorited videos')
            }
        });
    }

    const onClickRemove = (videoId) => {
        
        const variable = {
            videoId: videoId,
            userFrom:  localStorage.getItem('userId')
        }

        Axios.post('/api/favorite/removeFromFavorite', variable)
        .then(response=> {
            if(response.data.success) {
                fetchFavoritedVideos();
            } else {
                alert(' Failed to remove from favorite')
            }
        })
    }

    const renderTableBody = FavoritedVideos.map((video, index) => {

        const content = (
            <div>
                {video.videoId ? 
                <img src={`${IMAGE_URL}w500${video.videoId}`} alt="videoId" />
                : 
                "no Image"    
            }
            </div>
        )

        return <tr>

            <td>{video.videoTitle} {content}</td>
            <td>{video.videoRunTime} mins</td>
            <td><button onClick={()=>onClickRemove(video.videoId)}>
                Remove from the Favorites</button></td>
        </tr>


    })


    return (
        <div style={{ width: '85%', margin: '3rem auto' }}>
            <h3>Favorite Videos By Me</h3>
            <hr />

            <table>
                <thead>
                    <tr>
                        <th>Video Title	</th>
                        <th>Video RunTime	</th>
                        <th>Remove from favorites</th>
                    </tr>
                </thead>
                <tbody>

                    {renderTableBody}

                </tbody>
            </table>
        </div>
    )
}

export default FavoritePage