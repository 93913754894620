import React from 'react'

const CategoryTable = props => (
  <table>
    <thead>
      <tr>
        <th>Name</th>
        <th>ID</th>
        <th>Parent</th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
      {props.categories.length > 0 ? (
        props.categories.map(category => (
          <tr key={category._id}>
            <td>{category.name}</td>
            <td>{category._id}</td>
            <td>{category.parent}</td>
            <td>
              <button
                onClick={() => {
                  props.editRow(category)
                }}
                className="button muted-button"
              >
                Edit
              </button>
              <button
                onClick={() => props.deleteCategory(category._id)}
                className="button muted-button"
              >
                Delete
              </button>
            </td>
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan={3}>No users</td>
        </tr>
      )}
    </tbody>
  </table>
)

export default CategoryTable
