import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"; //, createAction
import axios from "axios";
// TODO: maybe change USER_SERVER to baseUrl, we can move this to utils folder
import { GROUP_SERVER } from '../../../components/Config.js';

//action to redirect
// const resetEditAction = createAction("category/reset");
// const resetDeleteAction = createAction("category/delete-reset");
// const resetCategoryAction = createAction("category/created-reset");

//createAsyncThunk (action, call back function)
// create
export const createGroupAction = createAsyncThunk(
  `${GROUP_SERVER}/create`,
  async (group, { rejectWithValue, getState, dispatch }) => {
    const user = getState()?.users;
    const { userAuth } = user;
    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
      },
    };
    //http call
    try {
      // TODO: update middleware for server to check for token cookie OR from the header
      const { data } = await axios.post(
      `${GROUP_SERVER}`,
      {
        name: group?.name,
        label: group?.label,
        //user: user?.id,
      },
      config);

      //dispatch action
      //dispatch(resetCategoryAction());

      if (!data?.success){
        return rejectWithValue(data);
      }

      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

//fetch
export const fetchGroupsAction = createAsyncThunk(
  `${GROUP_SERVER}/fetch`,
  async (group, { rejectWithValue, getState, dispatch }) => {
    //get user token
    const user = getState()?.users;
    const { userAuth } = user;
    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
      },
    };
    //http call
    try {
      // call the api GET group/
      const { data } = await axios.get(`${GROUP_SERVER}`);

      if (!data?.success){
        return rejectWithValue(data);
      }
      // from server/routes/group.js GET
      return data.groups;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

// export const fetchPostsAction = createAsyncThunk(
//   `${POST_SERVER}/list`,
//   async (group, { rejectWithValue, getState, dispatch }) => {
//     try {
//       const { data } = await axios.get(
//         `${POST_SERVER}?group=${group}`
//       );
//       return data;
//     } catch (error) {
//       if (!error?.response) throw error;
//       return rejectWithValue(error?.response?.data);
//     }
//   }
// );






//Update
export const updateGroupsAction = createAsyncThunk(
  `${GROUP_SERVER}/update`,
  async (group, { rejectWithValue, getState, dispatch }) => {
    //get user token
    const user = getState()?.users;
    const { userAuth } = user;
    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
      },
    };
    //http call
    try {
      const { data } = await axios.put(
        `${GROUP_SERVER}/${group?.id}`,
        { name: group?.name },
        config
      );

      //dispatch ation to reset the updated data
      //dispatch(resetEditAction());
      
      if (!data?.success){
        return rejectWithValue(data);
      }

      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

//delete
export const deleteGroupsAction = createAsyncThunk(
  `${GROUP_SERVER}/delete`,
  async (id, { rejectWithValue, getState, dispatch }) => {
    //get user token
    const user = getState()?.users;
    const { userAuth } = user;
    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
      },
    };
    //http call
    try {
      const { data } = await axios.delete(
        `${GROUP_SERVER}/${id}`,
        config
      );

      //dispatch action
      //dispatch(resetDeleteAction());

      if (!data?.success){
        return rejectWithValue(data);
      }

      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);


//fetch details
export const fetchGroupAction = createAsyncThunk(
  `${GROUP_SERVER}/details`,
  async (id, { rejectWithValue, getState, dispatch }) => {
    //get user token
    const user = getState()?.users;
    const { userAuth } = user;
    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
      },
    };
    //http call
    try {
      const { data } = await axios.get(`${GROUP_SERVER}/${id}`, config);

      if (!data?.success){
        return rejectWithValue(data);
      }

      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

// reducer / slices
const groupSlices = createSlice({
  name: "group",
  initialState: {  },
  extraReducers: builder => {
    //create
    builder.addCase(createGroupAction.pending, (state, action) => {
      state.loading = true;
    });
    //dispatch action to redirect
    // builder.addCase(resetCategoryAction, (state, action) => {
    //   state.isCreated = true;
    // });
    builder.addCase(createGroupAction.fulfilled, (state, action) => {
      state.group = action?.payload;
      state.loading = false;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(createGroupAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });
    //fetch all
    builder.addCase(fetchGroupsAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchGroupsAction.fulfilled, (state, action) => {
      state.groupList = action?.payload;
      state.loading = false;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(fetchGroupsAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });
    //update
    builder.addCase(updateGroupsAction.pending, (state, action) => {
      state.loading = true;
    });
    //Dispatch action
    // builder.addCase(resetEditAction, (state, action) => {
    //   state.isEdited = true;
    // });
    builder.addCase(updateGroupsAction.fulfilled, (state, action) => {
      state.updateGroup = action?.payload;
      //state.isEdited = false;
      state.loading = false;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(updateGroupsAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });
    //delete
    builder.addCase(deleteGroupsAction.pending, (state, action) => {
      state.loading = true;
    });
    //dispatch for redirect
    // builder.addCase(resetDeleteAction, (state, action) => {
    //   state.isDeleted = true;
    // });
    builder.addCase(deleteGroupsAction.fulfilled, (state, action) => {
      state.deletedGroup = action?.payload;
      //state.isDeleted = false;
      state.loading = false;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(deleteGroupsAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });
    //fetch details
    builder.addCase(fetchGroupAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchGroupAction.fulfilled, (state, action) => {
      state.group = action?.payload;
      state.loading = false;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(fetchGroupAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });
  },
});

export default groupSlices.reducer;
