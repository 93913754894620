import { configureStore } from "@reduxjs/toolkit";
import usersReducer from "../slices/users/usersSlices";
import groupReducer from "../slices/group/groupSlices";
import postReducer from "../slices/posts/postSlices";
import commentReducer from "../slices/postComments/commentSlices";

const store = configureStore({
  reducer: {
    users: usersReducer,
    group: groupReducer,
    post: postReducer,
    comment: commentReducer
  },
});

export default store;