import React, { useEffect, useState } from 'react'
import axios from 'axios';

function Favorite(props) {

    const [FavoriteNumber, setFavoriteNumber] = useState(0);
    const [Favorited, setFavorited] = useState(false);

    const variable = {
        userFrom: props.userFrom,
        videoId: props.videoId,
        videoTitle: props.videoTitle,
        videoImage: props.videoImage,
        videoRunTime: props.videoRunTime
    }

    useEffect(() => {

        axios.post('/api/favorite/favoriteNumber', variable)
            .then(response => {
                if (response.data.success) {
                    setFavoriteNumber(response.data.favoriteNumber);
                    //setVideo(response.data.video);
                    //setVideoId(response.data.video._id);
                    //document.title = 'BJJ Drills Technique: ' + response.data.video.title;
                } else {
                    alert('Failed to get favorite Number');
                }
            });

        axios.post('/api/favorite/favorited', variable)
            .then(response => {
                if (response.data.success) {
                    setFavorited(response.data.favorited);
                    //setVideo(response.data.video);
                    //setVideoId(response.data.video._id);
                    //document.title = 'BJJ Drills Technique: ' + response.data.video.title;
                } else {
                    alert('Failed to get Favorite Info');
                }
            });
    }, []);

    const onClickFavorite = () => {
        if (Favorited) {
            //  We already added

            axios.post('/api/favorite/removeFromFavorite', variable)
            .then(response => {
                if (response.data.success) {
                    setFavoriteNumber(FavoriteNumber - 1);
                    setFavorited(!Favorited);
                } else {
                    alert('Failed to remove from Favoritess');
                }
            });

        } else {
            // Not added yet

            axios.post('/api/favorite/addToFavorite', variable)
            .then(response => {
                if (response.data.success) {
                    setFavoriteNumber(FavoriteNumber + 1);
                    setFavorited(!Favorited);
                } else {
                    alert('Failed to add Favorites');
                }
            });
        }
    }

    return (
        <div>
            <button onClick={onClickFavorite}>{Favorited ? " remove from Favorite " : " Add to Favorite"} {FavoriteNumber}</button>
        </div>
    )
}

export default Favorite