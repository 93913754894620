import React, { useState, useEffect } from 'react'

const EditContributorForm = props => {
  const [ contributor, setContributor ] = useState(props.currentContributor)

  useEffect(
    () => {
      setContributor(props.currentContributor);
    },
    [ props ]
  )
  // You can tell React to skip applying an effect if certain values haven’t changed between re-renders. [ props ]

  const handleInputChange = event => {
    const { name, value } = event.target;

    setContributor({ ...contributor, [name]: value });
  }

  return (
    <form
      onSubmit={event => {
        event.preventDefault();

        props.updateContributor(contributor._id, contributor);
      }}
    >

      <label>Name</label>
      <input type="text" name="name" value={contributor.name} onChange={handleInputChange} />
      <label>Facebook</label>
      <input type="text" name="facebook" value={contributor.facebook} onChange={handleInputChange} />
      <label>Youtube</label>
      <input type="text" name="youtube" value={contributor.youtube} onChange={handleInputChange} />
      <label>Instagram</label>
      <input type="text" name="instagram" value={contributor.instagram} onChange={handleInputChange} />
      <label>Website</label>
      <input type="text" name="website" value={contributor.website} onChange={handleInputChange} />
      
      <button>Update user</button>
      <button onClick={() => props.setEditing(false)} className="button muted-button">
        Cancel
      </button>
    </form>
  )
}

export default EditContributorForm
