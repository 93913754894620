import React, { useState } from 'react'
import axios from 'axios';

function ChainedSelect(props) {
	// There was an issue if I pic box1 option2 (index = 1) (where option1, index = 0, would just be 'Pick One'), 
	// then box2 option2 (index = 1). Then I got back to box1 and change my selection to option3 (index = 2)
	// then box2 would not start from "Pick One" (''), it would have in memory the previous index and select that, in this case option2 (index = 1)
	// Adding the state fixed this issue. 
	const [CurrentValue, setCurrentValue] = useState("");

	var index = props.index + 1;

	// Run when select is picked
	const changeSelect = (event) => {
		event.preventDefault();

		let value = event.currentTarget.value;
		setCurrentValue(value);
		console.log('chain value:', value)
		//axios.post('/api/technique/getTechniques', {techniqueId: event.currentTarget.value})
		if (value){
			axios.post('/api/category/get-categories-by-parent', {techniqueId: value})
			
			.then(response => {
				if (response.data.success) {
					console.log('#changeSelect', response.data.techniques, index, value)
					props.refreshFunction(response.data.techniques, index, value);
				} else {
					alert('Failed to get video Info')
				}
			});
		} else {
			// reset the drop downs if 'Pick one' is selected
			console.log('reset', index)
			props.refreshFunction([], index-1, '');
		}
	}
// https://stackoverflow.com/questions/21733847/react-jsx-selecting-selected-on-selected-select-option
// might need to save the selected value because there is a bit of a glitch
	return (
		<div>
			{/*  defaultValue={''} */}
			<select onChange={changeSelect} value={CurrentValue} className="form-control">
				<option value="">Pick One</option>
				{props.TechniqueList && props.TechniqueList.length > 0 && props.TechniqueList.map((item, index) => (
					<option key={index} value={item._id}>{item.name}</option>
				))}
			</select>
		</div>
	)
}

export default ChainedSelect