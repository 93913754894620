import React, { useEffect, useState, Fragment } from 'react';
import axios from 'axios';
import AddContributorForm from './forms/AddContributorForm';
import EditContributorForm from './forms/EditContributorForm';
import ContributorTable from './tables/ContributorTable';

function ContributorsFormPage(props) {
    const initialFormState = { _id: null, name: '', facebook: '', youtube: '', instagram: '', website: '' }
    // https://medium.com/swlh/crud-operations-on-mongodb-tree-data-structure-f5afaeca1550
    // https://taniarascia.github.io/react-hooks/
    // https://www.taniarascia.com/crud-app-in-react-with-hooks/
    // Setting state
    //const [ users, setUsers ] = useState(usersData);
    const [currentContributor, setCurrentContributor] = useState(initialFormState);
    const [editing, setEditing] = useState(false);
    const [contributors, setContributors] = useState([]);
    

    useEffect(() => {
        axios.post('/api/contributor/getContributors', {})
            .then(response => {
                if (response.data.success) {
                    setContributors(response.data.contributors);
                } else {
                    alert('Failed to get video Info');
                }
            });
    }, []);

    // CRUD operations
    const addContributor = contributor => {
        
        axios.post('/api/contributor/addContributor', contributor)
            .then(response => {
                if (response.data.success) {
                    console.log('response.data.contributor',response.data.contributor);
                    contributor._id = response.data.contributor._id;
                    setContributors([ ...contributors, response.data.contributor ]);
                    //setVideos(response.data.videos)
                } else {
                    alert('Failed to save Contributor');
                }
            });
    }

    const deleteContributor = _id => {
        setEditing(false);

        setContributors(contributors.filter(contributor => contributor._id !== _id));

        var variables = {'contributor_id': _id};

        axios.post('/api/contributor/removeContributor', variables)
            .then(response => {
                if (response.data.success) {
                    console.log('success');
                    //setVideos(response.data.videos);
                } else {
                    alert('Failed to get Videos');
                }
            });
    }

    const updateContributor = (_id, updatedContributor) => {
        setEditing(false);
        // TODO: check to see if the parent id is different then the old one
        setContributors(contributors.map(contributor => (contributor._id === _id ? updatedContributor : contributor)));

        var variables = {'contributor_id': _id,
                        'name': updatedContributor.name};

        axios.post('/api/contributor/renameContributor', variables)
            .then(response => {
                if (response.data.success) {
                    console.log('success');
                    //setVideos(response.data.videos);
                } else {
                    alert('Failed to get Videos');
                }
            });
    }

    const editRow = contributor => {
        setEditing(true);

        setCurrentContributor({ _id: contributor._id, name: contributor.name, facebook: contributor.facebook, youtube: contributor.youtube, instagram: contributor.instagram, website: contributor.website });
    }

    return (
        <div className="row2">
            <div className="flex-row">
                <div className="flex-large">
                    {editing ? (
                        <Fragment>
                            <h2>Edit Contributor</h2>
                            <EditContributorForm
                                editing={editing}
                                setEditing={setEditing}
                                currentContributor={currentContributor}
                                updateContributor={updateContributor}
                            />
                        </Fragment>
                    ) : (
                        <Fragment>
                            <h2>Add Contributor</h2>
                            <AddContributorForm addContributor={addContributor} />
                        </Fragment>
                    )}
                </div>
                <div className="flex-large">
                    <h2>View Contributor</h2>
                    <ContributorTable contributors={contributors} editRow={editRow} deleteContributor={deleteContributor} />
                </div>
            </div>
        </div>
    )
}

export default ContributorsFormPage
