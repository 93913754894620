import React, {useEffect, useState} from 'react'
import axios from 'axios';

function SideVideo(props) {

    const [SideVideos, setSideVideos] = useState([]);
    const variable = {
        category: props.category.all_parents,
        slug: props.slug
    }

    // TODO: Maybe just go through all the categories, join them, find all videos from each category
    useEffect(() => {
        axios.post('/api/video/', variable)
            .then(response => {
                if (response.data.success) {
                    setSideVideos(response.data.videos)
                } else {
                    alert('Failed to get Videos')
                }
            })
    }, []);

    const sideVideoItem = SideVideos.map(( video, index) => {
        var domain = window.location.host === 'bjjdrills.com' || window.location.host === 'www.bjjdrills.com' ? window.location.origin + '/' : 'http://localhost:5000/';

        return <div className="col-12 pb-2" key={index}>
        <a href={`/video/${video.slug}`}  style={{ color:'gray' }}>
            <img style={{ width: '100%' }} className="img-fluid" width="320" height="180" alt="{video.title}" src={`${domain}${video.thumbnail}`} onMouseOver={e => (e.currentTarget.src = domain + video.gifPreviewFilePath)} onMouseOut={e => (e.currentTarget.src = domain + video.thumbnail)} />
            <span style={{ fontSize: '1rem', color: 'black' }}>{video.title}</span><br />
        </a>
    </div>
    });

    return (
        <React.Fragment>
            <div className="row">
                <h4 className="widget-title">Related Techniques:</h4>
                {sideVideoItem}
            </div>
            
        </React.Fragment>
    )
}

export default SideVideo