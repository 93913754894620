import React, { useState } from 'react';
import { useLocation } from "react-router-dom";
import LeftMenu from './Sections/LeftMenu';
//import RightMenu from './Sections/RightMenu';
import './Sections/Navbar.css';
import axios from 'axios';
import SearchFeature from '../../utils/SearchFeature';
import { useSelector } from "react-redux";

import AdminNavbar from "./Sections/AdminNavbar";
import PrivateNavbar from "./Sections/PrivateNavbar";
import PublicNavbar from "./Sections/PublicNavbar";

function NavBar() {
  //const state = useSelector(state => state.users);
  //const { userAuth } = state;
  //const isAdmin = userAuth?.isAdmin;

  // TODO: we are gonna have to go back to state.user, not state.users like the new tutorial
  //const user = useSelector(state => state.user);
  const [visible, setVisible] = useState(false);
  const [Limit, setLimit] = useState(8);
  const [Skip, setSkip] = useState(0);
  const state = useSelector(state => state.users);
  const { userAuth } = state;
  const isAdmin = userAuth?.isAdmin;
  console.log(isAdmin);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };
/*
  const [showNavigation, setShowNavigation] = useState(false);
  // showNavigation === true && 
  axios.get('https://www.cloudflare.com/cdn-cgi/trace').then(data => {
      if (data && data.data && data.data.indexOf("") !== -1){
          setShowNavigation(true);
      }
  });
*/
  const updateSearchTerms = (newSearchTerm) => {

    // const variables = {
    //     searchTerm: newSearchTerm,
    //     skip: Skip,
    //     limit: Limit,
    // }

    // setSkip(0);
    // setSearchTerms(newSearchTerm);
  }

  let location = useLocation();

  return (
  <header>
    {location.pathname !== '/' &&
    /* The header outside of the home page */
    <div className="m-4">
      <div className="container">
        <div className="row d-flex align-items-center">
          <div className="col-xl-3 col-lg-3 col-md-3 col-5 d-flex align-items-center">
            <a title="BJJ Drills Home" href="/">
              <img src="/bjjdrillslogo.png" alt="BJJ Drills Logo" height="80" className="img-fluid" />
            </a>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-7 d-flex align-items-center m-0" id="site-search">
            <SearchFeature refreshFunction={updateSearchTerms} />
          </div>
          <div className="col-xl-3 col-lg-3 col-md-3 col-12 d-flex align-items-center justify-content-end" id="header-social">
            
          </div>
        </div>
      </div>
    </div>
    }
{/*
    {user && user.userData && user.userData.isAuth &&
      <div></div>
      }
*/}
    <div id="navigation-wrapper" className="sticky-top">
      <div className="container">
        <nav className="navbar navbar-expand-lg navbar-light">
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" style={{display:'none'}}>
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            {isAdmin ? (
              <AdminNavbar mode="horizontal" />
            ) : userAuth ? (
              <PrivateNavbar mode="horizontal" />
            ) : (
              <PublicNavbar mode="horizontal" />
            )}
          </div>
        </nav>
      </div>
    </div>

  </header>
  )
}

export default NavBar