import React, { useEffect, useState, Fragment } from 'react';
import axios from 'axios';
import AddCategoryForm from './forms/AddCategoryForm';
import EditCategoryForm from './forms/EditCategoryForm';
import CategoryTable from './tables/CategoryTable';

function CategoriesFormPage(props) {
    const initialFormState = { _id: null, name: '', parent: '' };
    // https://medium.com/swlh/crud-operations-on-mongodb-tree-data-structure-f5afaeca1550
    // https://taniarascia.github.io/react-hooks/
    // https://www.taniarascia.com/crud-app-in-react-with-hooks/
    // Setting state
    //const [ users, setUsers ] = useState(usersData);
    const [ currentCategory, setCurrentCategory ] = useState(initialFormState);
    const [ editing, setEditing ] = useState(false);
    const [ categories, setCategories ] = useState([]);
    

    useEffect(() => {
        axios.post('/api/category/getCategories', {})
            .then(response => {
                if (response.data.success) {
                    setCategories(response.data.categories);
                } else {
                    alert('Failed to get video Info');
                }
            })
    }, []);

    // CRUD operations
    const addCategory = category => {
        

        axios.post('/api/category/addCategory', category)
            .then(response => {
                if (response.data.success) {
                    category._id = response.data.category._id;
                    setCategories([ ...categories, response.data.category ]);
                    //setVideos(response.data.videos)
                } else {
                    alert('Failed to get Videos');
                }
            });
    }

    const deleteCategory = _id => {
        setEditing(false);

        setCategories(categories.filter(category => category._id !== _id));

        var variables = {'category_id': _id};

        axios.post('/api/category/removeCategory', variables)
            .then(response => {
                if (response.data.success) {
                    console.log('success');
                    //setVideos(response.data.videos);
                } else {
                    alert('Failed to get Videos');
                }
            });
    }

    const updateCategory = (_id, updatedCategory) => {
        setEditing(false);
        // TODO: check to see if the parent id is different then the old one
        setCategories(categories.map(category => (category._id === _id ? updatedCategory : category)));

        var variables = {'category_id': _id,
                        'name': updatedCategory.name};

        axios.post('/api/category/renameCategory', variables)
            .then(response => {
                if (response.data.success) {
                    console.log('success');
                    //setVideos(response.data.videos);
                } else {
                    alert('Failed to get Videos');
                }
            });
    }

    const editRow = category => {
        setEditing(true);

        setCurrentCategory({ _id: category._id, name: category.name, parent: category.parent });
    }

    return (
        <div className="row2">
            <div className="flex-row">
                <div className="flex-large">
                    {editing ? (
                        <Fragment>
                            <h2>Edit Category</h2>
                            <EditCategoryForm
                                editing={editing}
                                setEditing={setEditing}
                                currentCategory={currentCategory}
                                updateCategory={updateCategory}
                            />
                        </Fragment>
                    ) : (
                        <Fragment>
                            <h2>Add Category</h2>
                            <AddCategoryForm addCategory={addCategory} />
                        </Fragment>
                    )}
                </div>
                <div className="flex-large">
                    <h2>View Category</h2>
                    <CategoryTable categories={categories} editRow={editRow} deleteCategory={deleteCategory} />
                </div>
            </div>
        </div>
    )
}

export default CategoriesFormPage
