import React, { useEffect, useState } from 'react'
import { Card, Avatar, Col } from 'antd';
import axios from 'axios';
import moment from 'moment';
import SearchFeature from '../../utils/SearchFeature';
import { DOMAIN_URL } from '../../Config';
import ChainedSelect from '../../utils/ChainedSelect';
//import { updateTechnique } from '../../utils/functions';

const { Meta } = Card;

function LandingPage() {

    const [Videos, setVideos] = useState([]);
    const [SearchTerms, setSearchTerms] = useState("");
    const [TopSearchTerms, setTopSearchTerms] = useState([]);
    const [Limit, setLimit] = useState(12);
    const [Skip, setSkip] = useState(0);
    // total videos in DB used for the Show More button
    const [VideoSize, setVideoSize] = useState(0);
    const [TechniqueList, setTechniqueList] = useState([]);
    // The most child selected technique ID number
    const [TechniqueValue, setTechniqueValue] = useState("");
    // All technique selected values
    const [AllTechniqueValue, setAllTechniqueValue] = useState([]);

    const techniqueVariable = {
        techniqueId: null
    };

    useEffect(() => {

        axios.post('/api/category/get-categories-by-parent', techniqueVariable)
        .then(response => {
            if (response.data.success) {
                setTechniqueList([response.data.techniques]);
            } else {
                alert('Failed to get categories')
            }
        });

        const variables = {
            skip: Skip,
            limit: Limit,
        }

        // Get most recently viewed products
        getProducts(variables);

        // Get top searched terms
        axios.get('/api/searchhistory/get-top-searched', {})
        .then(response => {
            if (response.data.success) {
                response.data.history = response.data.history.filter((history, index, self) =>
                    index === self.findIndex((h) => (
                        h?.term?.toLowerCase() === history?.term?.toLowerCase()
                    ))
                );
                setTopSearchTerms(response.data.history);
            } else {
                alert('Failed to get top searched')
            }
        });

    }, []);

    // newTechnique is the child
    const updateTechnique = (newTechnique, index, value) => {

        // console.log('-TechniqueList:', TechniqueList);
        // console.log('-newTechnique:', newTechnique);
        // console.log('-value', value);
        // console.log('-index', index);

        // At the most child value
        setTechniqueValue(value);
        // TODO: maybe remove this, I have an issue when a drop down gets removed, then when added back, it adds the previous indexed saved option
        AllTechniqueValue[index] = value;
        setAllTechniqueValue([...AllTechniqueValue]);


        // TODO: check to see if this is working properly - TechniqueList.splice(index + 1, TechniqueList.length - index + 1);
        // Do not run if no children exist on the child
        if (newTechnique.length != 0){
            if (TechniqueList.length - 1 >= index){
                TechniqueList[index] = newTechnique;

                // Remove anything that exists after the index
                // When something is selected before again
                TechniqueList.splice(index + 1, TechniqueList.length - index + 1);

                // [...] updates the state
                setTechniqueList([...TechniqueList]);
            } else {
                setTechniqueList(TechniqueList.concat([newTechnique]));
            }


            // UPDATE ONLY FOR LANDING PAGE
            let category = [];
            category.push(value);

            const variables = {
                skip: Skip,
                limit: Limit,
                category: category
            }

            // Get most recently viewed products
            getProducts(variables, true);

        } else {
            TechniqueList.splice(index + 1, TechniqueList.length - index + 1);
        }
    }

    // Get most recently viewed products
    const getProducts = (variables, reset = false) => {
        axios.post('/api/video/', variables)
        .then(response => {
            if (response.data.success) {
                // removed to for the add more button
                //setVideos(response.data.videos)
                if (reset){
                    setVideos(response.data.videos);
                } else {
                    setVideos([...Videos, ...response.data.videos]);
                }
                setVideoSize(response.data.videoSize);
            } else {
                alert('Failed to get Videos')
            }
        });

        // Axios.post('/api/product/getProducts', variables)
        //     .then(response => {
        //         if (response.data.success) {
        //             if (variables.loadMore) {
        //                 setProducts([...Products, ...response.data.products])
        //             } else {
        //                 setProducts(response.data.products)
        //             }
        //             setPostSize(response.data.postSize)
        //         } else {
        //             alert('Failed to fectch product datas')
        //         }
        //     })
    }
/*
    const renderCards = Videos.map((video, index) => {

        var minutes = Math.floor(video.duration / 60);
        var seconds = Math.floor(video.duration - minutes * 60);

        return <Col lg={6} md={8} xs={24}>
            <div style={{ position: 'relative' }}>
                <a href={`/video/${video.id}`} >
                <img style={{ width: '100%' }} alt="thumbnail" src={`http://localhost:5000/${video.thumbnail}`} />
                <div className=" duration d-none"
                    style={{ bottom: 0, right:0, position: 'absolute', margin: '4px', 
                    color: '#fff', backgroundColor: 'rgba(17, 17, 17, 0.8)', opacity: 0.8, 
                    padding: '2px 4px', borderRadius:'2px', letterSpacing:'0.5px', fontSize:'12px',
                    fontWeight:'500', lineHeight:'12px' }}>
                    <span>{minutes} : {seconds}</span>
                </div>
                </a>
            </div><br />
            <Meta
                avatar={
                    <Avatar src={video.writer.image} />
                }
                title={video.title}
            />
            <span>{video.writer.name} </span><br />
            <span style={{ marginLeft: '3rem' }}> {video.views}</span>
            - <span> {moment(video.createdAt).format("MMM Do YY")} </span>
        </Col>

    });
*/
    const renderCards2 = Videos.map((video, index) => {

        return  <div className="col-sm-12 col-md-6 col-lg-4 col-xl-2 py-1" key={index}>
                    <div style={{ position: 'relative' }}>
                        <a href={`/video/${video.slug}`} >
                            {/* https://stackoverflow.com/questions/48703510/change-image-on-hover-in-jsx */}
                            <img style={{ width: '100%' }} className="img-fluid" width="320" height="180" alt={video.title} src={`${DOMAIN_URL}${video.thumbnail}`} onMouseOver={e => (e.currentTarget.src = DOMAIN_URL + video.gifPreviewFilePath)} onMouseOut={e => (e.currentTarget.src = DOMAIN_URL + video.thumbnail)} />
                        </a>
                    </div>
                    <div className="my-1 font-weight-bold2" style={{fontSize: '14px'}}><a href={`/video/${video.slug}`} >{video.title}</a></div>
                </div>
                
    });

    const onLoadMore = () => {
        let skip = Skip + Limit;

        const variables = {
            skip: skip,
            limit: Limit,
        }

        getProducts(variables);
        setSkip(skip);
    }

    const updateSearchTerms = (newSearchTerm) => {

        const variables = {
            searchTerm: newSearchTerm,
            skip: Skip,
            limit: Limit,
        }

        setSkip(0);
        setSearchTerms(newSearchTerm);

        getProducts(variables);
    }
    
    const properCaseHelper = (str) => {
        console.log('lllllllllllllll', str)
        return str?.replace(
            /\w\S*/g,
            function(txt) {
              return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }
        );
    }

    return (
    <div className="row">
        <div className="col-md-12">

            {/* Search  */}

            <SearchFeature refreshFunction={updateSearchTerms} topSearchTerms={TopSearchTerms} />

            {TopSearchTerms.length !== 0 && 
                <div className="text-center m-4 d-none">
                    <span>Top Searches: </span>
                    {TopSearchTerms.map((term, index) => (
                        <a href={'/search/' + term.term}>{ (index ? ', ' : '') + properCaseHelper(term.term) }</a>
                    ))}
                </div>
            }

            <h4 className="widget-title">{SearchTerms ? 'Search Results' : 'Trending BJJ Drills'}</h4>
            
            <div className="row">
                <div className="d-flex justify-content-start">
                    {false && TechniqueList.map((technique, index) => (
                        <ChainedSelect TechniqueList={technique} refreshFunction={updateTechnique} index={index} key={index} />
                    ))}
                </div>
             </div>

            <div className="row">
                {renderCards2}
            </div>

            {VideoSize >= Limit &&
            <div className="row text-center">
                <div className="col text-center">
                    <button onClick={onLoadMore} type="button" className="btn btn-dark">Load More</button>
                </div>
            </div>
            }
        </div>
    </div>
    )
}

export default LandingPage