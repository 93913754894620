import React, { useEffect, useState } from 'react';
import { List, Avatar, Row, Col, Button } from 'antd';
import axios from 'axios';
import { DOMAIN_URL } from '../../Config';
import { useSelector } from "react-redux";
import { Redirect, Switch } from 'react-router-dom';

import SideVideo from './Sections/SideVideo';
import Subscriber from './Sections/Subscriber';
import Comments from './Sections/Comments'
import LikeDislikes from './Sections/LikeDislikes';
import Favorite from './Sections/Favorite';

function DetailVideoPage(props) {
    const user = useSelector(state => state.users);
    const slug = props.match.params.slug;
    const [videoId, setVideoId] = useState("");
    const [Video, setVideo] = useState([]);
    const [redirect, setRedirect] = useState(false);
    const [CommentLists, setCommentLists] = useState([]);

    const videoVariable = {
        slug: slug
    }

    useEffect(() => {
        axios.post('/api/video/get-video', videoVariable)
            .then(response => {
               
                if (response.data.success) {
                    setVideo(response.data.video);
                    setVideoId(response.data.video._id);
                    document.title = 'BJJ Drills Technique: ' + response.data.video.title;
                } else {
                    console.log('failed')
                    setRedirect(true);
                    //window.location = "https://bjjdrills.com";
                    //alert('Failed to get video Info');
                }
            })
        // TODO: show comments in the future
        /*
        axios.post('/api/comment/getComments', videoVariable)
            .then(response => {
                if (response.data.success) {
                    setCommentLists(response.data.comments);
                } else {
                    alert('Failed to get video Info');
                }
            })
        */

    }, []);
/*
    const updateComment = (newComment) => {
        setCommentLists(CommentLists.concat(newComment))
    }
*/

    const categoryLength = Video?.category?.all_names.length || 0;
    console.log('categoryLength', categoryLength, Video?.category?.all_names)

    if (Video.User) {
        return (
            <div className="row">
                <div className="col-sm-9">
                    <h1 className="display-5">{Video.title}</h1>
                    <div className="align-middle">
                        {Video.category &&
                            <strong>Category:</strong>
                        }
                        {Video.category && Video.category.all_names && Video.category.all_names.split(",").map((name, index) => 
                            <span className="pl-1" key="{index}">{index != 0 && <i className="fas fa-chevron-right" style={{ fontSize: '10px' }}></i> } {name}</span>
                        )}
                    </div>

                    <div>
                        <video style={{ width: '100%' }} controls autoPlay={true} loop muted playsInline>
                            <source src={`${DOMAIN_URL}${Video.mp4FilePath}`} type="video/mp4" />
                        </video>
                    </div>

                    {Video.contributor && 
                        <div>
                            Contributor: {Video.contributor.name}
                            {Video.contributor.facebook && 
                                <a href={Video.contributor.facebook} className="mx-2" rel="nofollow"><i className="fab fa-facebook"></i></a>
                            }
                            {Video.contributor.twitter && 
                                <a href={Video.contributor.twitter} className="mx-2" rel="nofollow"><i className="fab fa-twitter"></i></a>
                            }
                            {Video.contributor.instagram && 
                                <a href={Video.contributor.instagram} className="mx-2" rel="nofollow"><i className="fab fa-instagram"></i></a>
                            }
                            {Video.contributor.youtube && 
                                <a href={Video.contributor.youtube} className="mx-2" rel="nofollow"><i className="fab fa-youtube"></i></a>
                            }
                            {Video.contributor.website && 
                                <a href={Video.contributor.website} className="mx-2" rel="nofollow"><i className="fas fa-globe"></i></a>
                            }  
                        </div>
                    }

                    {user && user.userAuth && user.userAuth.isAuth &&
                    <Favorite userFrom={localStorage.getItem('userId')} videoId={Video.id} videoTitle={Video.title} videoImage={Video.thumbnail} videoRunTime={Video.duration} />
                    }

                    {user && user.userAuth && user.userAuth.isAuth &&
                    <div style={{display:'none'}}>

                        {/*
                        <div className="postPage" style={{ width: '100%', padding: '3rem 4em', display: 'none' }}>
                            <List.Item
                                actions={[<LikeDislikes video videoId={Video.id} userFrom={localStorage.getItem('userId')}  />, <Subscriber userTo={Video.User.id} userFrom={localStorage.getItem('userId')} />]}
                            >
                                <List.Item.Meta
                                    avatar={<Avatar src={Video.User && Video.User.image} />}
                                    title={<a href="https://ant.design">{Video.title}</a>}
                                    description={Video.description}
                                />
                                <div></div>
                            </List.Item>

                            <Comments CommentLists={CommentLists} postId={Video.id} refreshFunction={updateComment} />
                        </div>
                    */}
                    </div>
                    }
                    <p>{Video.description}</p>
                </div>
                <div className="col-sm-3">
                     <SideVideo category={Video.category} slug={Video.slug} />
                </div>
            </div>

        )

    } else {
        return (
            <React.Fragment>
                {redirect==true &&
                    <Switch>
                        <Redirect to ="/" />
                    </Switch>
                }
                <div>Loading...</div>
            </React.Fragment>
        )
    }
}

export default DetailVideoPage