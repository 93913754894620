import React, { useState } from 'react'
import { Input } from 'antd';
import { useLocation, useHistory } from "react-router-dom";

function SearchFeature(props) {
    let history = useHistory();
    let location = useLocation();

    const [SearchTerms, setSearchTerms] = useState("");

    const onChangeSearch = (event) => {
        setSearchTerms(event.currentTarget.value);
        // Put back if you want to have a live change
        //props.refreshFunction(event.currentTarget.value);

    }

    const handleSubmit = (event) => {
        event.preventDefault();
        // Add back if you want to load the search results after clicking the search button
        //props.refreshFunction(SearchTerms);
        if(SearchTerms){
            history.push('/search/' + SearchTerms.toLowerCase());
        }
    }

    const properCaseHelper = (str) => {
        console.log('mmmmmm', str)
        return str?.replace(
            /\w\S*/g,
            function(txt) {
              return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }
        );
    }

    if (location.pathname === '/'){
        return (
            <form className="row domain-search bg-pblue" onSubmit={handleSubmit} action="/search/"  method="get">
                <div className="row w-100">
                    <div className="col-md-12 text-center">
                        <img src="/bjjdrillslogo.png" alt="BJJ Drills | Find your techniques" width="200" className="m-sm-3 text-center" />
                    </div>
                </div>
            
                <div className="row w-100">

                    <div className="d-none d-lg-block col-lg-4 col-xl-4">
                        <h1 className="form-title">Find Your <strong>Jiu Jitsu Drills</strong></h1>
                        <p>Search for any BJJ technique</p>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-8 col-xl-8">
                        <p className="slim my-3"><strong>Find the move you would like to work on and download it for future reference</strong></p>
                        <div className="input-group">
                            <input type="search" className="form-control" value={SearchTerms} onChange={onChangeSearch} placeholder="Worm Guard, Bolo, Triangle Choke..." /> 
                            <span className="input-group-addon"><button type="submit" value="Search" className="btn search-button"><i className="fas fa-lg fa-search"></i></button></span> 
                        </div>
                        <p>{props.topSearchTerms.length !== 0 && 
                            <strong className="mt-3">
                                <span>Top Searches: </span>
                                {props.topSearchTerms.map((term, index) => (
                                    <a href={'/search/' + term.term}>{ (index ? ', ' : '') + properCaseHelper(term.term) }</a>
                                ))}
                            </strong>
                        }</p>
                    </div>
                </div>
            </form>
        )
    } else {
        return (
            <form onSubmit={handleSubmit} action="/search/"  method="get">
                <div className="row">
                    <div className="col-md-12">
                        <div className="input-group"> <input type="search" className="form-control" value={SearchTerms} onChange={onChangeSearch} placeholder="Worm Guard, Bolo, Triangle Choke..." /> 
                            <span className="input-group-addon"><input type="submit" value="Search" className="btn search-button" /></span> 
                        </div>
                    </div>
                </div>
            </form>
        )
    }
}

export default SearchFeature