import React, { useState, useEffect } from 'react';
//import { Redirect } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { updateGroupsAction } from "../../../../redux/slices/group/groupSlices";

const formSchema = Yup.object({
  name: Yup.string().required("Name is required"),
});

const EditGroupForm = props => {
  console.log('props', props)
  const [ group, setGroup ] = useState(props.currentGroup)

  const dispatch = useDispatch();
  //formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: group.name,
      id: group._id
    },
    onSubmit: values => {
      //dispath the action
      console.log('values', values)
      dispatch(updateGroupsAction(values));
    },
    validationSchema: formSchema,
  });

  //get data from store
  const state = useSelector(state => state?.group);
  // todo: should be group not category
  const { loading, appErr, serverErr, category } = state; //, isEdited, isDeleted

  
  //redirect
  //if (isEdited || isDeleted) return <Redirect to="/category-list" />;
  return (
    <form onSubmit={formik.handleSubmit}>
      <label>Name</label>{}
      <input type="text" name="name" value={formik.values.name} onChange={formik.handleChange("name")} onBlur={formik.handleBlur("name")} />
      <input type="hidden" name="id" value={formik.values.id} />
      {formik.touched.name && formik.errors.name}
      {appErr || serverErr ? (
                <h2 className="text-red-500 text-center text-lg">
                  {serverErr} {appErr}
                </h2>
              ) : null}
      <button type="submit">{ loading ? <div>Please wait</div> : <div>Update Group</div> }</button>
      <button type="submit" onClick={() => props.setEditing(false)} className="button muted-button">
        Cancel
      </button>
    </form>
  )
}

export default EditGroupForm
