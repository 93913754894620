import { useEffect } from "react";
//import { ThumbUpIcon, ThumbDownIcon, EyeIcon } from "@heroicons/react/solid";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchPostsAction, toggleAddLikesToPost, toggleAddDisLikesToPost } from "../../../redux/slices/posts/postSlices";
import DateFormatter from "../../utils/DateFormatter";
import { fetchGroupsAction } from "../../../redux/slices/group/groupSlices";
import LoadingComponent from "../../utils/LoadingComponent";

export default function ListPost() {

  const post = useSelector(state => state?.post);
  const { postLists, loading, appErr, serverErr, likes, dislikes } = post;
  console.log(post);

  const group = useSelector(state => state?.group);
  const { groupList, loading: groupLoading, appErr: groupAppErr, serverErr: groupServerErr } = group;
console.log('groupList',groupList)



  const dispatch = useDispatch();

  //fetch post
  useEffect(() => {
    dispatch(fetchPostsAction(""));
  }, [dispatch, likes, dislikes]); // Refetch post each time there is a like or dislikes

  //fetch categories
  useEffect(() => {
    dispatch(fetchGroupsAction());
  }, [dispatch]);

  return (
    <>
      <div>
        {/* View All */}
        <button
          onClick={() => dispatch(fetchPostsAction(""))}
          className="inline-block py-2 px-6 rounded-l-xl rounded-t-xl bg-green-600 hover:bg-green-700 text-gray-50 font-bold leading-loose transition duration-200"
        >
          View All Posts
        </button>

        <h4 className="mb-4 text-gray-500 font-bold uppercase">
            Categories
          </h4>
          <ul>
            {groupLoading ? (
              <LoadingComponent />
            ) : groupAppErr || groupServerErr ? (
              <h1>
                {groupServerErr} {groupAppErr}
              </h1>
            ) : groupList?.length <= 0 ? (
              <h1>No Category Found</h1>
            ) : (
              groupList?.map(group => (
                <li>
                  <p
                    onClick={() =>
                      dispatch(fetchPostsAction(group?.label))
                    }
                    className="block cursor-pointer py-2 px-3 mb-4 rounded text-yellow-500 font-bold bg-gray-500"
                  >
                    {group?.label}
                  </p>
                </li>
              ))
            )}
</ul>
</div>

            {/* Post goes here */}

                {appErr || serverErr ? (
                  <h1>
                    {serverErr} {appErr}
                  </h1>
                ) : postLists?.length <= 0 ? (
                  <h1>No Post Found</h1>
                ) : (
                  postLists?.map(post => (




                    <div
                      key={post.id}
                      className="flex flex-wrap bg-gray-900 -mx-3  lg:mb-6"
                    >
                      <div class="mb-10  w-full lg:w-1/4 px-3">
                        <Link>
                          {/* Post image */}
                          <img
                            class="w-full h-full object-cover rounded"
                            src={post?.image}
                            alt=""
                          />
                        </Link>
                        {/* Likes, views dislikes */}
                        <div className="flex flex-row bg-gray-300 justify-center w-full  items-center ">
                          {/* Likes */}
                          <div className="flex flex-row justify-center items-center ml-4 mr-4 pb-2 pt-1">
                            {/* Togle like  */}
                            <div className="">
                              <span
                                onClick={() =>
                                  dispatch(toggleAddLikesToPost(post?._id))
                                }
                                className="h-7 w-7 text-indigo-600 cursor-pointer"
                              >up</span>
                            </div>
                            <div className="pl-2 text-gray-600">
                              {post?.likes?.length}
                            </div>
                          </div>
                          {/* Dislike */}
                          <div className="flex flex-row  justify-center items-center ml-4 mr-4 pb-2 pt-1">
                            <div>
                              <span
                                onClick={() =>
                                  dispatch(toggleAddDisLikesToPost(post?._id))
                                }
                                className="h-7 w-7 cursor-pointer text-gray-600"
                              >down</span>
                            </div>
                            <div className="pl-2 text-gray-600">
                              {post?.dislikes?.length}
                            </div>
                          </div>
                          {/* Views */}
                          <div className="flex flex-row justify-center items-center ml-4 mr-4 pb-2 pt-1">
                            <div>
                              Views
                            </div>
                            <div className="pl-2 text-gray-600">
                              {post?.numViews}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="w-full lg:w-3/4 px-3">
                        <Link class="hover:underline" to={`/posts/${post?._id}`}>
                          <h3 class="mb-1 text-2xl text-green-400 font-bold font-heading">
                            {/* {capitalizeWord(post?.title)} */}
                            {post?.title}
                          </h3>
                        </Link>
                        <p class="text-gray-300">{post?.description}</p>
                        {/* Read more */}
                        <Link
                          to={`/posts/${post?._id}`}
                          className="text-indigo-500 hover:underline"
                        >
                          Read More..
                        </Link>
                        {/* User Avatar */}
                        <div className="mt-6 flex items-center">
                          <div className="flex-shrink-0">
                            <Link>
                              <img
                                className="h-10 w-10 rounded-full"
                                src={post?.user?.profilePhoto}
                                alt=""
                              />
                            </Link>
                          </div>
                          <div className="ml-3">
                            <p className="text-sm font-medium text-gray-900">
                              <Link className="text-yellow-400 hover:underline ">
                                {post?.user?.name}
                              </Link>
                            </p>
                            <div className="flex space-x-1 text-sm text-green-500">
                              <time>
                                <DateFormatter date={post?.createdAt} />
                              </time>
                              <span aria-hidden="true">&middot;</span>
                            </div>
                          </div>
                        </div>
                        {/* <p class="text-gray-500">
                             Quisque id sagittis turpis. Nulla sollicitudin rutrum
                             eros eu dictum...
                           </p> */}
                      </div>
                    </div>



                  ))
                )}
          
      
    </>
  );
}
