import React from 'react'

const ContributorTable = props => (
  <table>
    <thead>
      <tr>
        <th>Name</th>
        <th>ID</th>
        <th>Facebook</th>
        <th>Instagram</th>
        <th>Youtube</th>
        <th>Parent</th>
        <th>Website</th>
      </tr>
    </thead>
    <tbody>
      {props.contributors.length > 0 ? (
        props.contributors.map(contributor => (
          <tr key={contributor._id}>
            <td>{contributor.name}</td>
            <td>{contributor._id}</td>
            <td>{contributor.facebook}</td>
            <td>{contributor.instagram}</td>
            <td>{contributor.youtube}</td>
            <td>{contributor.website}</td>
            <td>
              <button
                onClick={() => {
                  props.editRow(contributor)
                }}
                className="button muted-button"
              >
                Edit
              </button>
              <button
                onClick={() => props.deleteContributor(contributor._id)}
                className="button muted-button"
              >
                Delete
              </button>
            </td>
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan={7}>No users</td>
        </tr>
      )}
    </tbody>
  </table>
)

export default ContributorTable
