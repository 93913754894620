/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Menu } from 'antd';
import axios from 'axios';
import { USER_SERVER } from '../../../Config';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { logoutAction } from "../../../../redux/slices/users/usersSlices";

function PublicNavbar(props) {
  //const user = useSelector(state => state.users);

  // const logoutHandler = () => {
  //   axios.get(`${USER_SERVER}/logout`).then(response => {
  //     if (response.status === 200) {
  //       props.history.push("/login");
  //     } else {
  //       alert('Log Out Failed')
  //     }
  //   });
  // };

  const dispatch = useDispatch();

  return (
    <ul className="navbar-nav mr-auto d-none">
      <li className="nav-item active" mode={props.mode}>
        <a className="nav-link" href="/">Home <span className="sr-only">(current)</span></a>
      </li>
      <li className="nav-item" mode={props.mode}>
        <a className="nav-link" href="/login"><i className="fa fa-sign-in-alt"></i> Signin</a>
      </li>
      <li className="nav-item" mode={props.mode}>
        <a className="nav-link" href="/register"><i className="fa fa-sign-in-alt"></i> Signup</a>
      </li>
    </ul>
  )
}

export default withRouter(PublicNavbar);