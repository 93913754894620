import React, { Suspense } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
//import Auth from "../hoc/auth";
// pages for this product
import LandingPage from "./views/LandingPage/LandingPage.js";
import LoginPage from "./views/LoginPage/LoginPage.js";
import RegisterPage from "./views/RegisterPage/RegisterPage.js";
import NavBar from "./views/NavBar/NavBar";
import Footer from "./views/Footer/Footer";
import UploadVideoPage from "./views/UploadVideoPage/UploadVideoPage";
import DetailVideoPage from "./views/DetailVideoPage/DetailVideoPage";
import SubscriptionPage from "./views/SubscriptionPage/SubscriptionPage";
import SearchResultsPage from "./views/SearchResultsPage/SearchResultsPage";
import CategoryFormPage from "./views/CategoryFormPage/CategoryFormPage";
import GroupFormPage from "./views/GroupFormPage/GroupFormPage";
import ContributorFormPage from "./views/ContributorFormPage/ContributorFormPage";
import FavoritePage from "./views/FavoritePage/FavoritePage";
import PrivateProtectRoute from "./views/ProtectedRoutes/PrivateProtectRoute";
import AdminRoute from "./views/ProtectedRoutes/AdminRoute";
import CreatePostPage from "./views/PostPage/CreatePost";
import ListPostPage from "./views/PostPage/ListPost";
import DetailPostPage from "./views/PostPage/DetailPost";
import UpdatePostPage from "./views/PostPage/UpdatePost";
import UpdateCommentPage from "./views/PostComments/UpdateComment";
import ProfilePage from "./views/UserProfilePage/Profile";
import UploadProfilePhotoPage from "./views/UserProfilePage/UploadProfilePhoto";
import UpdateProfilePage from "./views/UserProfilePage/UpdateProfileForm";
//null   Anyone Can go inside
//true   only logged in user can go inside
//false  logged in user can't go inside

function App() {
  //https://stackoverflow.com/questions/47281850/how-to-hide-navbar-in-login-page-in-react-router
  //https://codesandbox.io/s/puu2o?file=/src/App.js
  //https://codesandbox.io/s/strange-khorana-6oysl?file=/src/App.js
  //https://stackoverflow.com/questions/66206075/how-to-add-protected-routes-in-react-redux
  //https://stackoverflow.com/questions/70719690/private-route-in-react-router-v6-taking-authentication-using-redux
  //https://stackoverflow.com/questions/62154408/redirect-page-upon-login-using-react-router-v5-and-redux-toolkit
  //https://www.bezkoder.com/react-redux-login-example-toolkit-hooks/

  // Redirect pages to home page if route does not exist
  //<Route exact path="*" component={RegisterPage} /> //element={<Redirect to="/" />} 
  //<Route component={() => (<div>404 Not found </div>)} />
  //window.history.pushState({}, null, "/newPathname");
  //<Redirect to="/" />
  return (
    <Suspense fallback={(<div>Loading...</div>)}>
      <NavBar />
      <main role="main" className="container flex-fill">
        <Switch>
          <Route exact path="/" component={LandingPage} />
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/register" component={RegisterPage} />
          // Update other routes to take admin and logged in user at the same time
          <AdminRoute exact path="/video/upload" component={UploadVideoPage} />
          <Route exact path="/video/:slug" component={DetailVideoPage} />
          <AdminRoute exact path="/subscription" component={SubscriptionPage} />
          <Route exact path="/search/:searchTerm" component={SearchResultsPage} />
          <AdminRoute exact path="/categoryform" component={CategoryFormPage} />
          <AdminRoute exact path="/createpost" component={CreatePostPage} />
          <AdminRoute exact path="/updatepost/:slug" component={UpdatePostPage} />
          <AdminRoute exact path="/update-comment/:slug" component={UpdateCommentPage} />
          <Route exact path="/posts" component={ListPostPage} />
          <Route exact path="/posts/:slug" component={DetailPostPage} />
          <AdminRoute exact path="/groupform" component={GroupFormPage} />
          <AdminRoute exact path="/favorite" component={FavoritePage} />
          <AdminRoute exact path="/contributorform" component={ContributorFormPage} />
          <PrivateProtectRoute exact path="/profile/:slug" component={ProfilePage} />
          <PrivateProtectRoute exact path="/upload-profile-photo/" component={UploadProfilePhotoPage} />
          <PrivateProtectRoute exact path="/update-profile/:slug" component={UpdateProfilePage} />
          <Redirect to="/" />
        </Switch>
      </main>
      <Footer />
    </Suspense>
  );
}

export default App;